import { WarningFilled } from '@element-plus/icons-vue';
export default {
  components: {
    WarningFilled
  },
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      errorList: this.list
    };
  },
  watch: {
    list(val) {
      this.errorList = val;
    }
  }
};
import BaseTable from '../../common/base-table.vue';
export default {
  components: {
    BaseTable
  },
  props: {
    form: {
      type: Object,
      default: null
    },
    stayTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      waybillForm: this.form,
      time: this.stayTime
    };
  },
  computed: {
    table() {
      let tableData = this.form.logistic_tracking;
      let tableFields = [{
        label: '轨迹时间',
        prop: 'event_time',
        width: '100px',
        show: true
      }, {
        label: '轨迹地址',
        prop: 'event_address',
        show: true
      }, {
        label: '轨迹详情',
        prop: 'event_detail',
        width: '400px',
        show: true
      }, {
        label: '运输子状态',
        prop: 'transit_sub_state',
        show: true
      }];
      return {
        tableData,
        tableFields
      };
    }
  },
  watch: {
    form(val) {
      this.waybillForm = val;
    },
    stayTime(val) {
      this.time = val;
    }
  }
};
import { getState, getCity } from '../../../utils/state-city.js';
import { getCountryIso3 } from '../../../utils/index.js';
export default {
  props: {
    form: {
      type: Object,
      default: null
    },
    countryOption: {
      type: Array,
      default: null
    },
    stateOption: {
      type: Array,
      default: null
    },
    cityOption: {
      type: Array,
      default: null
    },
    warehouseOption: {
      type: Array,
      default: null
    },
    parcelType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      skuOption: [],
      orderOption: [],
      skuLoading: false,
      orderLoading: false,
      addressVisible: false,
      city: this.cityOption,
      state: this.stateOption,
      waybillForm: this.form,
      country: this.countryOption,
      waybillType: this.parcelType,
      warehouse: this.warehouseOption,
      waybillRules: {
        waybill_no: [{
          required: true,
          message: '请输入'
        }],
        order_no: [{
          required: true,
          message: '请输入订单号'
        }],
        postcode: [{
          required: true,
          message: '请输入'
        }, {
          min: 1,
          max: 20,
          message: '最多只允许输入20位'
        }],
        warehouse_id: [{
          required: true,
          message: '请选择仓库'
        }],
        sku: [{
          required: true,
          message: '请输入SKU'
        }],
        shipping_time: [{
          required: true,
          message: '请选择发货时间'
        }],
        country_id: [{
          required: true,
          message: '请选择客户国家'
        }],
        address: [{
          required: false,
          message: '请输入客户地址'
        }, {
          min: 1,
          max: 100,
          message: '最多只允许输入100位'
        }],
        name: [{
          required: false,
          message: '请输入客户姓名'
        }, {
          min: 1,
          max: 40,
          message: '最多只允许输入40位'
        }],
        phone: [{
          required: false
        }, {
          pattern: /^[A-Za-z0-9-.+ ()\/]+$/,
          message: '只允许输入数字、英文、＋、空格、中划线、点、/、()英文括号'
        }, {
          min: 1,
          max: 30,
          message: '最多只允许输入30位'
        }],
        email: [{
          required: false
        }, {
          pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
          message: '输入的邮箱不合法'
        }, {
          min: 1,
          max: 60,
          message: '最多只允许输入60位'
        }],
        remark: [{
          required: false
        }, {
          min: 1,
          max: 100,
          message: '最多只允许输入100位'
        }]
      }
    };
  },
  watch: {
    form(val) {
      this.waybillForm = val;
    },
    countryOption(val) {
      this.country = val;
    },
    stateOption(val) {
      this.state = val;
    },
    cityOption(val) {
      this.city = val;
    },
    warehouseOption(val) {
      this.warehouse = val;
    },
    'form.country_id'() {
      this.checkPostcode(this.waybillForm.postcode);
    },
    parcelType: {
      handler(val) {
        this.waybillType = val;
        this.setFormRequired(val);
      },
      immediate: true
    }
  },
  methods: {
    setFormRequired(val) {
      let requiredBit = val === 0 ? 7 : val === 1 ? 1 : 0;
      this.addressVisible = 1 & requiredBit;
      this.waybillRules.address[0].required = 2 & requiredBit;
      this.waybillRules.name[0].required = 4 & requiredBit;
    },
    checkPostcodeValid(val) {
      if (val) {
        this.checkPostcode(val);
      }
    },
    checkPostcode(postcode) {
      if (this.addressVisible) {
        let reg = getCountryIso3(this.waybillForm, this.country);
        if (reg) {
          if (!reg.test(postcode)) {
            this.$message.error('输入的邮编与选定的客户国家不匹配，请检查');
          }
        }
      }
    },
    async remoteMethod(query, prop) {
      if (query) {
        try {
          if (prop === 'sku_id') {
            this.skuLoading = true;
            await this.$store.dispatch('getSku', {
              params: {
                name: query
              }
            });
            this.skuOption = this.$store.state.sku;
            this.skuLoading = false;
          } else {
            this.orderLoading = true;
            await this.$store.dispatch('getOrder', {
              params: {
                order_no: query
              }
            });
            this.orderOption = this.$store.state.order;
            this.orderLoading = false;
          }
        } catch (err) {
          return;
        }
      } else {
        if (prop === 'sku_id') {
          this.skuOption = [];
        } else {
          this.orderOption = [];
        }
      }
    },
    async changeId(val, prop) {
      this.waybillForm[prop] = val;
    },
    clearCountry() {
      this.waybillForm.state_id = 0;
      this.waybillForm.city_id = 0;
    },
    clearState() {
      this.waybillForm.city_id = 0;
    },
    async changeCountry(country) {
      this.clearCountry();
      getState(country).then(res => {
        this.state = res;
      });
    },
    async getCity(country, state) {
      this.clearState();
      getCity(country, state).then(res => {
        this.city = res;
      });
    }
  }
};
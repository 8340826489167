import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WarningFilled = _resolveComponent("WarningFilled");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createBlock(_component_el_table, {
    border: "",
    data: $data.errorList,
    style: {
      "width": "99%"
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$global.errorTableFields, item => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        key: item.prop,
        prop: item.prop,
        label: item.label,
        width: item.width ? item.width : '200px',
        align: "center"
      }, {
        default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(scope.row[item.prop].value), 1), scope.row[item.prop].error ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 0,
          placement: "top-start",
          title: "错误详情",
          width: 200,
          trigger: "click",
          content: scope.row[item.prop].error
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_icon, {
            color: "#EA1D1D",
            class: "error-msg"
          }, {
            default: _withCtx(() => [_createVNode(_component_WarningFilled)]),
            _: 1
          })]),
          _: 2
        }, 1032, ["content"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["prop", "label", "width"]);
    }), 128))]),
    _: 1
  }, 8, ["data"]);
}
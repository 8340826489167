import BaseForm from '../../common/base-form.vue';
import UpdateWaybill from './update-waybill.vue';
import ViewWaybill from './view-waybill.vue';
import ErrorTable from '../../common/error-table.vue';
import { ArrowDown, UploadFilled } from '@element-plus/icons-vue';
import { handleDateRange, timeToTimestamp, cache, getCountryIso3, defaultTime, checkStayTime } from '../../../utils/index.js';
import { getState, getCity } from '../../../utils/state-city.js';
export default {
  components: {
    BaseForm,
    ErrorTable,
    ArrowDown,
    ViewWaybill,
    UploadFilled,
    UpdateWaybill
  },
  data() {
    return {
      tableFields: [],
      listData: [],
      id: 0,
      chooseForm: {},
      activeTab: 'all',
      activeTabKey: '',
      pagination: {
        current_page: 1,
        page_size: 10
      },
      orderFormVisible: false,
      orderForm: {},
      orderFormType: '',
      logisticSupplierVisible: false,
      logisticSupplierForm: {},
      selectedIds: [],
      deleteWaybillVisible: false,
      labelList: [],
      deletedId: 0,
      updateWaybillVisible: false,
      updateWaybillForm: {},
      countryOption: [],
      stateOption: [],
      cityOption: [],
      warehouseOption: [],
      table: {},
      stayTime: '',
      viewWaybillVisible: false,
      waybillDetail: {},
      waybillId: 0,
      parcelType: 0,
      importWaybillVisible: false,
      waybillType: '',
      error: {},
      warehouse: [],
      transitState: this.$store.state.logistics.transitStateStatistics
    };
  },
  watch: {
    '$store.state.logistics.transitStateStatistics'(val) {
      this.transitState = val;
    }
  },
  mounted() {
    this.chooseForm.create_time = defaultTime(90);
    let column = JSON.parse(localStorage.getItem('logistics-column'));
    if (column) {
      this.tableFields = column;
    } else {
      this.tableFields = this.$global.logisticsTableFields;
    }
    this.getListData();
    this.getCountry();
  },
  methods: {
    cache,
    getDate(obj) {
      if (obj.prop === 'create_time') {
        this.chooseForm.create_time = obj.val;
        this.$store.commit('logistics/setDateChange', true);
      }
    },
    handleChoose(transitState) {
      if (!this.$store.state.logistics.isDateChange) {
        this.chooseForm.create_time = defaultTime(90);
      }
      handleDateRange(this.chooseForm, 'shipping_time');
      handleDateRange(this.chooseForm, 'create_time');
      let params = JSON.parse(JSON.stringify(this.chooseForm));
      if (params.multiple_waybill_no) {
        params.waybill_no_query_type = 2;
        params.waybill_no = params.multiple_waybill_no.replace(/\n/g, ',');
      } else {
        params.waybill_no_query_type = 1;
      }
      params.current_page = this.pagination.current_page;
      params.page_size = this.pagination.page_size;
      params.transit_state = transitState;
      if (this.chooseForm.warehouse_id.length === 0) {
        params.warehouse_id = this.warehouse;
      }
      for (let i in params) {
        if (Array.isArray(params[i]) && i !== 'create_time' && i !== 'shipping_time') {
          params[i] = params[i].join(',');
        }
      }
      return params;
    },
    getWarehouse(val) {
      this.warehouse = val;
    },
    async getListData(transitState = '') {
      let params = this.handleChoose(transitState);
      if (!params.start_create_time || !params.end_create_time) {
        this.$message.warning('创建时间为必填项');
      } else {
        try {
          this.$store.commit('logistics/setListLoading', true);
          await this.$store.dispatch('logistics/getListData', {
            params
          });
          this.listData = this.$store.state.logistics.listData;
          this.table = {
            tableFields: this.tableFields,
            tableData: this.listData
          };
          this.labelList = JSON.parse(cache('label'));
        } catch (err) {
          this.$store.commit('logistics/setListLoading', false);
          return;
        }
      }
    },
    queryList() {
      let form = JSON.parse(JSON.stringify(this.chooseForm));
      let startStayTimeValid = checkStayTime(form.start_event_day);
      let endStayTimeValid = checkStayTime(form.end_event_day);
      if (startStayTimeValid && endStayTimeValid) {
        this.pagination.current_page = 1;
        this.getListData(this.activeTabKey);
      }
    },
    resetForm() {
      this.activeTab = 'all';
      this.activeTabKey = '';
      this.pagination.current_page = 1;
      this.pagination.page_size = 10;
      this.chooseForm = {
        warehouse_id: []
      };
      this.warehouse = [];
      this.chooseForm.create_time = defaultTime(90);
      this.$store.commit('logistics/setDateChange', false);
      this.getListData();
    },
    handleClick(tab) {
      this.activeTabKey = this.$global.transitState.find(item => {
        return item.name === tab.props.name;
      }).key;
      this.pagination.current_page = 1;
      this.getListData(this.activeTabKey);
    },
    changePagination(val) {
      this.pagination = val;
      this.getListData(this.activeTabKey);
    },
    showOrderForm(type) {
      this.orderFormType = type;
      this.orderFormVisible = true;
    },
    submitOrderForm(type) {
      this.$refs.orderForm.$refs.form.validate(valid => {
        if (valid) {
          if (type === 'create') {
            this.updateOrderInfo(this.orderForm, type);
          } else {
            this.updateOrderInfo(this.orderForm, type);
          }
        }
      });
    },
    async updateOrderInfo(form, type) {
      let orderParams = JSON.parse(JSON.stringify(form));
      orderParams.payment_time = orderParams.payment_time ? timeToTimestamp(orderParams.payment_time) : 0;
      try {
        await this.$store.dispatch(`logistics/${type}Order`, orderParams);
        this.orderFormVisible = false;
        this.$refs.orderForm.$refs.form.resetFields();
      } catch (err) {
        return;
      }
    },
    closeOrderForm() {
      this.orderFormVisible = false;
      this.$refs.orderForm.$refs.form.resetFields();
    },
    async getOrderInfo(id) {
      try {
        if (id) {
          await this.$store.dispatch('logistics/getOrderDetail', {
            params: {
              id
            }
          });
          let orderDetail = this.$store.state.logistics.orderDetail;
          this.orderForm.platform_id = orderDetail.platform_id;
          this.orderForm.shop_id = orderDetail.shop_id;
          this.orderForm.payment_time = orderDetail.payment_time;
        } else {
          this.orderForm = {};
          this.$refs.orderForm.$refs.form.resetFields();
        }
      } catch (err) {
        return;
      }
    },
    closeLogisticSupplierForm() {
      this.logisticSupplierVisible = false;
      this.$refs.logisticSupplierForm.$refs.form.resetFields();
    },
    getSelectedIds(ids) {
      this.selectedIds = ids;
    },
    handleSelectedIds(fn) {
      if (this.selectedIds.length === 0) {
        this.$message.warning('请先选择要修改的运单');
      } else {
        fn();
      }
    },
    showUpdateLogisticSupplierForm() {
      this.handleSelectedIds(() => {
        this.logisticSupplierVisible = true;
      });
    },
    async updateLogisticSupplier() {
      let body = this.logisticSupplierForm;
      body.id = this.selectedIds;
      try {
        await this.$store.dispatch('logistics/updateLogisticSupplier', body);
        this.logisticSupplierVisible = false;
        this.$refs.logisticSupplierForm.$refs.form.resetFields();
        this.getListData(this.activeTabKey);
      } catch (err) {
        return;
      }
    },
    submitLogisticSupplierForm() {
      this.$refs.logisticSupplierForm.$refs.form.validate(valid => {
        if (valid) {
          this.updateLogisticSupplier();
        }
      });
    },
    handleExceptionHandling() {
      try {
        this.handleSelectedIds(async () => {
          await this.$store.dispatch('logistics/updateExceptionHandling', {
            id: this.selectedIds
          });
          this.getListData(this.activeTabKey);
        });
      } catch (err) {
        return;
      }
    },
    showDeleteWaybillDialog() {
      this.handleSelectedIds(() => {
        this.deleteWaybillVisible = true;
      });
    },
    closeDeleteWaybillDialog() {
      this.deleteWaybillVisible = false;
    },
    async confirmDeletionWaybill() {
      try {
        await this.$store.dispatch('logistics/deleteWaybill', {
          id: this.selectedIds
        });
        this.deleteWaybillVisible = false;
        this.getListData(this.activeTabKey);
      } catch (err) {
        return;
      }
    },
    handleWaybillLabel(command) {
      try {
        this.handleSelectedIds(async () => {
          await this.$store.dispatch('logistics/updateWaybillLabel', {
            id: this.selectedIds,
            label_id: command
          });
          this.getListData(this.activeTabKey);
        });
      } catch (err) {
        return;
      }
    },
    async getDeletedId(body) {
      try {
        await this.$store.dispatch('logistics/deleteWaybillLabel', body);
        this.getListData(this.activeTabKey);
      } catch (err) {
        return;
      }
    },
    closeUpdateWaybillDialog() {
      this.updateWaybillVisible = false;
      this.$refs.updateWaybillForm.$refs.form.resetFields();
    },
    async updateWaybill(id, type) {
      this.waybillId = id;
      this.parcelType = type;
      if (cache('warehouse')) {
        this.warehouseOption = JSON.parse(cache('warehouse'));
        if (cache('logistics-country')) {
          this.countryOption = JSON.parse(cache('logistics-country'));
          try {
            await this.$store.dispatch('logistics/getBaseWaybillDetail', {
              params: {
                id
              }
            });
            this.updateWaybillForm = this.$store.state.logistics.baseWaybillDetail;
            if (this.updateWaybillForm.country_id) {
              getState(this.updateWaybillForm.country_id).then(res => {
                this.stateOption = res;
              });
              getCity(this.updateWaybillForm.country_id, this.updateWaybillForm.state_id).then(res => {
                this.cityOption = res;
              });
            }
            this.updateWaybillVisible = true;
          } catch (err) {
            return;
          }
        }
      } else {
        this.$message.warning('请刷新后再尝试！');
      }
    },
    checkPostcode() {
      let reg = getCountryIso3(this.updateWaybillForm, this.countryOption);
      if (reg && !reg.test(this.updateWaybillForm.postcode)) {
        this.$message.error('输入的邮编与选定的客户国家不匹配，请检查');
      } else {
        this.updateWaybillInfo();
      }
    },
    submitUpdateWaybillForm() {
      this.$refs.updateWaybillForm.$refs.form.validate(valid => {
        if (valid) {
          this.checkPostcode();
        }
      });
    },
    async updateWaybillInfo() {
      let body = JSON.parse(JSON.stringify(this.updateWaybillForm));
      body.id = this.waybillId;
      body.parcel_type = this.parcelType;
      try {
        await this.$store.dispatch('logistics/updateWaybill', body);
        this.updateWaybillVisible = false;
        this.getListData(this.activeTabKey);
      } catch (err) {
        return;
      }
    },
    async getCountry() {
      if (!cache('logistics-country')) {
        try {
          await this.$store.dispatch('getCountry');
        } catch (err) {
          return;
        }
      }
    },
    async viewWaybill(id, time, dialogFlag = true) {
      this.waybillId = id;
      try {
        await this.$store.dispatch('logistics/getWaybillDetail', {
          params: {
            id
          }
        });
        this.waybillDetail = this.$store.state.logistics.waybillDetail;
        this.stayTime = time;
        if (dialogFlag) {
          this.viewWaybillVisible = true;
        }
      } catch (err) {
        return;
      }
    },
    async asyncWaybillInfo() {
      try {
        await this.$store.dispatch('logistics/asyncWaybillInfo', {
          waybill_id: this.waybillId
        });
        this.viewWaybill(this.waybillId, this.stayTime, false);
        this.getListData(this.activeTabKey);
      } catch (err) {
        return;
      }
    },
    handleImport(type) {
      this.$store.commit('logistics/setStepActive', 1);
      this.waybillType = type;
      this.importWaybillVisible = true;
    },
    async downloadImportTemplate() {
      try {
        await this.$store.dispatch('logistics/exportTemplate', {
          parcel_type: +this.waybillType
        });
      } catch (err) {
        return;
      }
    },
    async importWaybill(e) {
      let file = e.file;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('parcel_type', this.waybillType);
      try {
        await this.$store.dispatch('logistics/importWaybill', formData);
        this.error = this.$store.state.logistics.error;
        if (JSON.stringify(this.error) === '{}') {
          this.importWaybillVisible = false;
          this.getListData(this.activeTabKey);
        }
      } catch (err) {
        return;
      }
    },
    backStep() {
      this.$store.commit('logistics/setStepActive', 1);
    },
    searchWaybillNo(val) {
      this.chooseForm = Object.assign(this.chooseForm, val);
      this.queryList();
    },
    async exportWaybill() {
      let body = this.handleChoose(this.activeTabKey);
      try {
        await this.$store.dispatch('logistics/exportWaybill', body);
      } catch (err) {
        return;
      }
    }
  }
};